import React, { useState } from "react";

/**Import Styles And Assets */
import styles from "./styles/index.module.scss";

/**Import Components */
import { TextInput } from "../../components/Fields";
import { sendNotification } from "../../Validation";
import { Notification as NotificationSender } from "../../Api/Notification";
import { removeSpecialCharacter } from "../../Prototypes";


/**Import Package Components */
import { Grid } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from '@mui/material/CircularProgress';
import { Toast } from "../../hooks/useToast";

export default function Notification() {


    const dispatch = useDispatch();
    const { uuid } = useSelector(state => state.profile.data);
    const { handleSubmit, control, formState: { errors } } = useForm({
        reValidateMode: "onSubmit",
        resolver: yupResolver(sendNotification)
    });


    const [isSubmitted, setIsSubmitted] = useState(false)

    async function onSubmit(data) {
        setIsSubmitted(true);
        try {
            Toast("Sending Notification", 'loading', true);
            const body = {
                title: data.title,
                body: data.description,
                topic: 'admin'
            }
            await NotificationSender.send(body);
            Toast("Notification Sent Successfully !", 'success', false);
        }
        catch (e) {
            Toast("Failed To Sent Notification !", 'error', false);
        }
        finally {
            setIsSubmitted(false);
            return;
        }

    }




    return (
        <div className={styles.notification}>
            <div className={styles.container}>
                <h1>Send Notification</h1>
                <hr />
                <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
                    <Grid container rowSpacing={3} columnSpacing={{ xs: 2 }}>
                        <Grid xs={12} item>
                            <TextInput
                                name="title"
                                label="Title"
                                control={control}
                                error={errors}
                            />

                            <TextInput
                                name="description"
                                label="description"
                                control={control}
                                error={errors}
                            />
                            {isSubmitted ?
                                <button className={`${styles.submit} ${styles.submitted}`} type="button">Send<CircularProgress size={20} /></button>
                                :
                                <button className={styles.submit} type="submit">Send</button>
                            }
                        </Grid>

                    </Grid>
                </form>

            </div >
        </div >
    )
}