import { useState } from "react";
import { TextInput, SelectDropdown, DateInput } from "../../../components/Fields";
import { FiMapPin } from "react-icons/fi";

export function useOfferAndPromotion({
    control,
    errors,
    geo_locations,
    setIsMap,
    timeData,
    handleTimeChange,
    disableStartTime = false,
    disableEndTime = false
}) {

    const categories_data = [
        {
            name: "Male"
        },
        {
            name: "Female"
        }
    ];
    const status = "success";




    const components = [
        {
            label: "",
            type: 'single',
            field: <TextInput
                name="title"
                type="text"
                label="Title"
                containerStyle={{ margin: 0 }}
                control={control}
                error={errors}

            />
        },
        {
            label: "Location Of Audience",
            type: 'single',
            field: <TextInput
                name="geo_location"
                type="text"
                label="Business Location"
                control={control}
                error={errors}
                containerStyle={{ margin: 0 }}
                value={geo_locations ? `${geo_locations}` : ""}
                icon={<FiMapPin onClick={() => setIsMap(true)} />}
                onFocus={() => { setIsMap(true) }}
                autoComplete={false}
                readOnly={true}

            />
        }, {
            label: "Age Group",
            type: 'single',
            field: <TextInput
                name="age_group"
                type="text"
                label="Age Group"
                containerStyle={{ margin: 0 }}
                control={control}
                error={errors}

            />
        }, {
            label: "Gender",
            type: 'single',
            field: <SelectDropdown
                name="gender"
                type="text"
                label="Gender"
                control={control}
                error={errors}
                data={categories_data}
                containerStyle={{ margin: 0 }}
                status={status}
                loading={status !== "success" ? true : false}
                selectValue="name"


            />
        },
        {
            label: "Select Start And End Date",
            type: 'double',
            field: [
                <DateInput
                    name="open_time"
                    control={control}
                    error={errors}
                    type="time"
                    label="Start Date"
                    value={timeData.open_time}
                    onChange={(newValue) => handleTimeChange("open_time", newValue)}
                    noMargin={true}
                    disablePast={true}
                    disableField={disableStartTime}
                />,
                <DateInput
                    name="close_time"
                    control={control}
                    error={errors}
                    type="time"
                    label="End Date"
                    value={timeData.close_time}
                    onChange={(newValue) => handleTimeChange("close_time", newValue)}
                    noMargin={true}
                    disablePast={true}
                    disableField={disableEndTime}
                />
            ]
        }
    ]

    return components;
}