import React, { useEffect, useState } from "react";
import ReactDom from "react-dom";
import styles from "./map.module.scss";

import { useLoadScript } from "@react-google-maps/api";
import { Toast } from "../../hooks/useToast"
import Map from "./Map.js"
import CircularProgress from '@mui/material/CircularProgress';
import _ from "lodash";


function MapContainer({ location, setLatLng, setClose }) {
    const [permission, setPermission] = useState("denied");
    const [position, setPosition] = useState(location)
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_MAP_API_KEY,
        libraries: ["places"]
    })

    useEffect(() => {

        navigator.geolocation.getCurrentPosition(
            function (position) {
                setPosition({
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                });
            },
            function (error) {
                Toast("Site Not Able To access Your location !", "info", false);    
            }
        );



    }, [permission])

    useEffect(() => {
        if (_.isEmpty(position)) {
            setLatLng('geo_location', "");
        } else {
            setLatLng('geo_location', JSON.stringify(position));
        }
    }, [position])

    useEffect(() => {
        navigator.permissions.query({ name: 'geolocation' }).then((permissionStatus) => {
            permissionStatus.onchange = () => {
                setPermission(permissionStatus.state)
            };
        });
    }, [])


    return (
        ReactDom.createPortal(<div className={styles.mapContainer}>
            <div className={styles.background} onClick={() => setClose(false)}>
            </div>
            <div className={styles.map}>
                {!isLoaded ?
                    <CircularProgress style={{ position: "absolute", zIndex: 4 }} /> :
                    <Map position={position} setPosition={setPosition} />
                }
            </div>
        </div>, document.getElementById("modal"))
    )
}

export default React.memo(MapContainer)