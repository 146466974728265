import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { Offers } from '../Api/Offers';
import { Toast } from '../hooks/useToast';


export const getOffersThunk = createAsyncThunk(
    'offers/get',
    async (_, { getState }) => {
        try {
            const { id, api_token } = getState().profile.data;
            const response = await Offers.get(id, api_token);
            return response.offers;
        } catch (err) {
            Toast(err.message, "error", false);
            return Promise.reject(err.message);
        }
    }
)

export const createOfferThunk = createAsyncThunk(
    'offer/create',
    async ({ body, setIsSubmitted, setIsCreateOffer }, { getState }) => {
        Toast("Creating New Offer ...", "loading", true);
        try {
            const { id, api_token } = getState().profile.data;
            body.business_id = id;
            const response = await Offers.create(body, api_token);
            Toast("Offer Created Successfully", "success", false);
            setIsCreateOffer(false);
            return response.offers[0];
        } catch (err) {
            Toast(err.message, "error", false);
            return Promise.reject(err.message);
        }
        finally {
            setIsSubmitted(false);
        }
    }
)


export const editOfferThunk = createAsyncThunk(
    'offer/edit',
    async ({ body, setIsSubmitted, setOfferToEdit }, { getState }) => {
        Toast("Updating Offer ...", "loading", true);
        try {
            const { id, api_token } = getState().profile.data;
            const data = {
                business_id: id,
                ...body
            }
            const response = await Offers.edit(data, api_token);
            Toast('Offer Updated Successfully', "success", false);
            setOfferToEdit(null);
            return response.offers[0];
        } catch (err) {
            Toast(err.message, "error", false);
            return Promise.reject(err.message);
        }
        finally {
            setIsSubmitted(false);
        }
    }
)


export const deleteOfferThunk = createAsyncThunk(
    'offer/delete',
    async ({ offer_id }, { getState }) => {
        Toast("Deleting Offer ...", "loading", true);
        try {
            const { id, api_token } = getState().profile.data;
            const body = {
                business_id: id,
                offer_id
            }
            const response = await Offers.delete(body, api_token);
            Toast(response.message, "success", false);
            return offer_id;
        } catch (err) {
            Toast(err.message, "error", false);
            return Promise.reject(err.message);
        }
    }
)


const initialState = {
    status: "pending",
    data: [],
}

export const offersSlice = createSlice({
    name: 'offers',
    initialState,
    reducers: {
        resetState: () => {
            return initialState;
        }
    },
    extraReducers: {
        [getOffersThunk.fulfilled]: (state, action) => {
            state.status = "success";
            state.data = action.payload;
        },
        [getOffersThunk.pending]: (state, action) => {
            state.status = "loading"
        },
        [getOffersThunk.rejected]: (state, action) => {
            state.status = "exception"
        },
        [createOfferThunk.fulfilled]: (state, action) => {
            state.status = "success";
            state.data = [
                ...state.data,
                action.payload
            ];
        },
        [createOfferThunk.pending]: (state, action) => {
            state.status = "success"
        },
        [createOfferThunk.rejected]: (state, action) => {
            state.status = "error"
        },

        [editOfferThunk.fulfilled]: (state, action) => {
            const obj = action.payload;
            const arr = state.data.map(item => {
                return (item.id == obj.id) ? obj : item
            });
            state.data = arr;
            state.status = "success";
        },
        [editOfferThunk.pending]: (state, action) => {
            state.status = "success";
        },
        [editOfferThunk.rejected]: (state, action) => {
            state.status = "error";
        },


        [deleteOfferThunk.fulfilled]: (state, action) => {
            const id = action.payload;
            const arr = state.data.filter(item => item.id != id);
            state.data = arr;
            state.status = "success";
        },
        [deleteOfferThunk.pending]: (state, action) => {
            state.status = "loading"
        },
        [deleteOfferThunk.rejected]: (state, action) => {
            state.status = "error"
        },
    }
})
export const { resetState } = offersSlice.actions

export default offersSlice.reducer