import React, { useEffect, useState } from 'react';

/**Import Styles */
import styles from "./styles/index.module.scss";

/**Import Components */
import { TextInput } from "../../components/Fields";

/**Import Package Components */
import { Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from '@mui/material/CircularProgress';
import { updatePasswordThunk, updateRewardPointsThunk } from "../../features/profileReducer";
import { Input, FormControl, OutlinedInput, InputLabel } from "@mui/material"
import { getRewardPointThunk } from '../../features/lookupsReducer';

export default function RewardManagement() {

    const BUTTON_TITLE = "Update Points";


    const dispatch = useDispatch();
    const [isSubmitted, setIsSubmitted] = useState(false);
    const { review_points } = useSelector(state => state.profile.data);
    const [points, setPoints] = useState(review_points ? review_points : 0);



    function onSubmit() {
        setIsSubmitted(true);
        dispatch(updateRewardPointsThunk({ points, setIsSubmitted }));
    }


    function handleChange(e) {
        const { value } = e.target;
        const val = parseFloat(value);
        if (val) {
            return setPoints(val);
        }
        else {
            return setPoints(0);
        }
    }


    return (
        <div className={styles.rewardManagement}>
            <div className={styles.container}>
                <h1>Reward Management</h1>
                <hr />
                <form className={styles.form}>
                    <Grid container rowSpacing={3} columnSpacing={{ xs: 2 }}>
                        <Grid xs={12} item>
                            <FormControl
                                fullWidth
                                classes={{
                                    root: styles.inputContainer
                                }}
                                size="small"
                            >
                                <InputLabel classes={{
                                    focused: styles.colored,
                                    shrink: styles.colored,
                                    root: styles.label
                                }}>Reward Per View</InputLabel>
                                <OutlinedInput
                                    type='number'
                                    name="reward"
                                    value={parseFloat(points).toString()}
                                    onChange={handleChange}
                                    classes={{
                                        root: styles.input,
                                    }}
                                />
                            </FormControl>
                            {isSubmitted ?
                                <button
                                    className={`${styles.submit} ${styles.submitted}`}
                                    type="button"

                                >
                                    {BUTTON_TITLE}
                                    <CircularProgress size={20} />
                                </button> :
                                <button
                                    className={styles.submit}
                                    onClick={onSubmit}
                                >
                                    {BUTTON_TITLE}
                                </button>
                            }
                        </Grid>

                    </Grid>
                </form>

            </div >
        </div >
    )
}