import { Grid } from "@mui/material";
import React from "react";

import styles from "../styles/Footer.module.scss";
import playstore from "../images/playstore.png";
import appstore from "../images/appstore.png";

import { FaFacebookF, FaLinkedinIn } from "react-icons/fa";
import { AiOutlineInstagram, AiOutlineGooglePlus } from "react-icons/ai";


export default function Footer() {

    const socialIcons = [<FaFacebookF />, <AiOutlineInstagram />, <FaLinkedinIn />, <AiOutlineGooglePlus />]
    const links =  ["home", "about", "terms-and-conditions", "privacy-and-policy", "contact-us"];
    return (
        <div className={styles.footer}>
            <Grid container className={styles.service} columnGap={8}>
                <Grid item xs={3} className={styles.tile}>
                    <h1>
                        LOGO HERE
                    </h1>
                    <p className={styles.subtitle}>
                        simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s
                    </p>
                    <div className={styles.socialContainer}>
                        {socialIcons.map((item, index) => <div className={styles.social} key={index}>{item}</div>)}



                    </div>

                </Grid>
                <Grid item xs={1.5} className={styles.tile}>
                    <h3 className={styles.title}>Information</h3>
                    <div className={styles.container}>
                        {links.map((item, index) => <p className={styles.links} key={index}>{item.replaceAll("-", " ").toUpperCase()}</p>)}
                    </div>

                </Grid>
                <Grid item xs={1.8} className={styles.tile}>
                    <h3 className={styles.title}>Download App Here</h3>
                    <div className={styles.container}>
                        <div className={styles.buttonContainer}>
                            <img src={playstore} alt="Play Store" />
                            <img src={appstore} alt="App Store" />
                        </div>
                    </div>

                </Grid>
                <Grid item xs={3} className={styles.tile}>
                    <h3 className={styles.title}>Contact Us</h3>
                    <div className={styles.container}>
                        <p className={styles.links}>Capital Office Ltd is registered in England And Wales 1 Registered</p>

                    </div>

                    <h3 className={styles.title}>Address</h3>
                    <div className={styles.container}>
                        <p className={styles.links}>Capital Office Ltd is registered in England And Wales 1 Registered</p>

                    </div>

                </Grid>


            </Grid>
            <div className={styles.copyright}>
                <p>Copyrights All Rights Reserved 2022</p>

            </div>

        </div>
    )
}