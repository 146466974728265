import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import styles from "./map.module.scss";

import _ from "lodash";
import {
    GoogleMap,
    Marker
} from "@react-google-maps/api";


export default function Map({ position, setPosition }) {

    const [map, setMap] = useState(false);
    const mapRef = useRef();
    const center = useMemo(() => ({ lat: 32.6135, lng: -97.730821 }), []);
    const options = useMemo(() => ({
        mapId: "17c975ad78fa8aef",
        disableDefaultUI: true,
        clickableIcons: false
    }))



    useEffect(() => {
            ; (async () => {
                if (mapRef.current && !_.isEmpty(position)) {
                    await mapRef.current.panTo(position);

                }
                else if (mapRef.current && _.isEmpty(position)) {
                    mapRef.current.setZoom(6);
                }
            })()

    }, [position])



    const onLoad = useCallback((map) => {
        mapRef.current = map;
    }, []);
    const onClick = ({ latLng }) => {
        const lat = latLng.lat();
        const lng = latLng.lng();

        setPosition({ lat, lng });
    }

    return (

        <GoogleMap
            zoom={6}
            center={_.isEmpty(position) ? center : position}
            mapContainerClassName={styles.googleMap}
            options={options} onLoad={onLoad}
            onClick={onClick}
        >
            {!_.isEmpty(position) && <Marker position={position} />}
        </GoogleMap>

    )
}