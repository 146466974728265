import React from "react";
import styles from "./ProtectedLayout.module.scss";
import moment from "moment"


import { AiOutlineSearch, AiFillBell } from "react-icons/ai";

import { useSelector, useDispatch } from "react-redux";

import logo from "./images/logo.png"
import defaultImage from "./images/defaultImage.png"


export default function Navbar({ isSearchBar }) {

    // const { search } = useSelector(state => state.search);
    // const dispatch = useDispatch();
    const { image_or_video_link: image_url } = useSelector(state => state.profile.data);



    return (
        <div className={styles.Navbar}>
            <div className={styles.brand}>
                <img src={logo} alt="Cirgle Logo" className={styles.image} />

            </div>
            {isSearchBar ?
                <div className={styles.search}>
                    <div className={styles.searchButton}>
                        <AiOutlineSearch />

                    </div>
                    <input type="text" placeholder="Search by name..." />
                </div>
                : ""}
            <div className={styles.icons}>
                {/* <div>
                    <AiFillBell />
                </div> */}
                <img src={image_url ? image_url : defaultImage} alt="Profile Image" />


            </div>

        </div >
    )
}