import { Axios } from "../hooks/useAxiosInstance";

export const Lookup = (function () {

    // const { api_token } = useSelector(state => state.profile);

    const apis = () => ({
        getCategory: async (id, api_token) => await getCategoryRecord(id, api_token),
        createMenuCategory: async (body, api_token) => await createMenuCategoryRecord(body, api_token),
        deleteMenuCategory: async (body, api_token) => await deleteMenuCategoryRecord(body, api_token),
        getRewardPoint: async (body, api_token) => await getRewardPointRecord(body, api_token),

    })

    async function getCategoryRecord(id, api_token) {
        const url = `api/business/getMCategory/${id}`;
        const option = {
            headers: {
                'Content-Type': 'application/json',
                'token': api_token
            }
        }

        return await Axios.get(url, option);
    }

    async function createMenuCategoryRecord(body, api_token) {
        const url = `api/business/createMCategory`;
        const option = {
            headers: {
                'Content-Type': 'application/json',
                'token': api_token,
            }
        }

        return await Axios.post(url, body, option);
    }
    async function deleteMenuCategoryRecord(body, api_token) {
        const url = `api/business/deleteMenuCategory`;
        const option = {
            headers: {
                'Content-Type': 'application/json',
                'token': api_token,
            }
        }

        return await Axios.post(url, body, option);
    }
    async function getRewardPointRecord(body, api_token) {
        const url = `api/user/getPoints`;
        const option = {
            headers: {
                'Content-Type': 'application/json',
                'token': api_token
            }
        }

        return await Axios.post(url, body, option);
    }
    return apis();

})()
