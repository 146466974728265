import React from "react";


/**Import Styles And Assets */
import styles from "./styles/PostDetails.module.scss";
import { IoIosArrowRoundBack } from "react-icons/io";
import defaultImage from "./images/defaultImage.png"

/**Import Apis */
import { Post } from "../../Api/Post"

/**Import Package Components */
import { CircularProgress, Grid } from "@mui/material";
import { FaHeart, FaComments } from 'react-icons/fa';
import { AiTwotoneDislike, AiFillEye } from 'react-icons/ai';
import {
    useQuery,
} from 'react-query'
import { useSelector } from "react-redux";
import _ from "lodash"
import Carousel from 'react-material-ui-carousel'
import { Paper, Button } from '@mui/material'
import { dateToFormattedString } from "../../Prototypes";

export default function PostDetails({ id, setShowPostDetail }) {

    const { api_token } = useSelector(state => state.profile.data);
    const { status, error, data } = useQuery({
        queryKey: [`post/${id}`],
        queryFn: () => Post.getByID(id, api_token)
    });


    const details = !_.isEmpty(data?.data[0]) ? data.data[0] : {};
    const comments = !_.isEmpty(data?.comments) ? data.comments : [];



    const statsArr = [
        {
            icon: <FaHeart className={styles.red} />,
            label: !_.isEmpty(details) ? details?.business_post_like : "0"
        },
        {
            icon: <FaComments />,
            label: !_.isEmpty(details) ? details?.posts_comments_count : '0'
        }
    ]

    function getCardThumbnail(media = {}) {

        let att = <img src={defaultImage} img="Card Image" className={styles.postImage} />;

        if (media.thumb == null) {
            att = <img src={media.url} img="Card Image" className={styles.postImage} />
        }
        else {
            att = <img src={media.thumb} img="Card Image" className={styles.postImage} />
        }

        return att;


    }



    return (
        <div className={styles.postDetails}>
            <div className={styles.backButtonContainer} onClick={() => setShowPostDetail(null)}>
                <IoIosArrowRoundBack className={styles.icon} />
                <h5 className={styles.back}>Back</h5>
            </div>
            {(status === 'loading')
                ? <div className={styles.loader}>
                    <CircularProgress size={20} />
                </div>

                :
                (status === 'error') ? <div className={styles.loader}><h3>Details Not Found</h3></div> :
                    < div className={styles.content}>
                        <div className={styles.product}>
                            <Grid container className={styles.detailsContainer}>
                                <Grid xl={4} xs={5} item container className={styles.image_stats_container}>
                                    <Grid xl={12} xs={12} item className={styles.imageContainer} order={{ xl: 1, xs: 1 }}>
                                        <Carousel className={styles.carousel} height="inherit">
                                            {
                                                details?.post_media.length ?
                                                    details?.post_media.map(item => getCardThumbnail(item)) :
                                                    <img src={defaultImage} img="Card Image" className={styles.postImage} />

                                            }
                                        </Carousel>

                                    </Grid>
                                    <Grid xl={12} xs={12} item container className={styles.statsContainer} order={{ xl: 2, xs: 2 }}>
                                        {statsArr.map(({ icon, label }, index) => {
                                            return <Grid xl={2.7} xs={3} item className={styles.counts} key={index}>
                                                {icon}
                                                <p className={styles.label}>{label}</p>
                                            </Grid>
                                        })}
                                    </Grid>
                                </Grid>
                                <Grid xl={8} xs={7} item className={styles.details}>
                                    <p className={styles.tag}>{details.post_title}</p>
                                    <p className={styles.info}>{details.post_description}</p>


                                </Grid>
                            </Grid>
                        </div>
                        <hr />
                        <div className={styles.comments}>
                            <Grid container className={styles.commentsContainer}>
                                <h5 className={styles.header}>Comments</h5>
                                <Grid item container xl={12} className={styles.userComments}>
                                    {!comments.length ? <div className={styles.emptyCommentContainer}>
                                        <h3 className={styles.emptyHeader}>No Comments Found</h3>
                                    </div> :
                                        comments.map((item, index) => <div className={styles.commentDetail} key={index}>
                                            <div className={styles.userInfo}>
                                                <div className={styles.userImageContainer}>
                                                    <img src={item.user_image} alt="User Image" className={styles.userImage} />
                                                </div>
                                                <div className={styles.info}>
                                                    <p className={styles.name}>{item.name}</p>
                                                    <p className={styles.date}>{dateToFormattedString(item.created_at)}</p>
                                                </div>

                                            </div>
                                            <div className={styles.userComment}>
                                                <p className={styles.commentText}>{item.message}</p>
                                            </div>
                                            <hr />


                                        </div>
                                        )}
                                </Grid>

                            </Grid>

                        </div>
                    </div>
            }
        </div >
    )
}


{/* <div className={styles.imageContainer}
style={{
    backgroundImage: `url(http://localhost:3000/cirgle-business/static/media/defaultImage.8772735f8086b6f7a7ed.png)`,

}}>
</div>
<Grid item container columnSpacing={0} className={styles.stats}>
{statsArr.map(({ icon, label }, index) => {
    return <Grid xs={3} item className={styles.counts} key={index}>
        {icon}
        <p className={styles.label}>{label}</p>
    </Grid>
})}


</Grid> */}
