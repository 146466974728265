import React from "react";

/**Import Styles and Assets */
import styles from "../styles/Brands.module.scss";
import brand1 from "../images/brand1.png"
import brand2 from "../images/brand2.png"
import brand3 from "../images/brand3.png"
import brand4 from "../images/brand4.png"

/**Import Packaged Component*/
import Grid from '@mui/material/Grid';

export default function Brands() {
    return (
        <div className={styles.brands}>
            <Grid container>
                <Grid md={7} item style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <div className={styles.content}>
                        <h1>OUR BRANDS</h1>
                        <p>
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                        </p>
                        <button className={styles.button}>
                            View All Brands
                        </button>

                    </div>

                </Grid>
                <Grid md={5} item className={styles.imageContainer}>
                    <Grid container >
                        <Grid md={12} item>
                            <div className={styles.contentImage}>
                                <img src={brand1} alt="About Image1" className={`${styles.image} ${styles.even}`} />
                                <img src={brand2} alt="About Image2" className={styles.image} />

                            </div>
                            <div className={styles.contentImage}>
                                <img src={brand3} alt="About Image1" className={`${styles.image} ${styles.even}`} />
                                <img src={brand4} alt="About Image2" className={styles.image} />

                            </div>
                        </Grid>

                    </Grid>
                </Grid>
            </Grid>

        </div>
    )
}