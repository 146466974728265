import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";


/** Import Styles And Assets */
import styles from "./styles/index.module.scss";
import banner from "./images/Banner.png";
import defaultImage from "./images/defaultImage.png";

/**Import Components */
import { deletePostThunk, getPostThunk } from "../../features/postReducer";
import CreatePostModal from "./CreatePostModal";


/**Import Package Components */
import { Grid } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import EditPostModal from "./EditPostModal";
import { RiDeleteBin6Line, RiEdit2Line } from "react-icons/ri"
import PostDetails from "./PostDetails";
import { confirmAlert } from 'react-confirm-alert';

export default function PostFeedManagement() {

    const DESCRIPTION_LENGTH = 120;
    const dispatch = useDispatch();
    const { data, status } = useSelector(state => state.post);

    const [showPostDetail, setShowPostDetail] = useState(null);
    const [isCreatePostModal, setIsCreatePostModal] = useState(false);
    const [isUpdated, setIsUpdated] = useState(true);
    const [postToEdit, setPostToEdit] = useState({});



    useEffect(() => {
        if (status !== 'success' && isUpdated) {
            dispatch(getPostThunk());
            setIsUpdated(false);

        }

    }, [isUpdated])



    function getCardThumbnail(media) {
        let att = <img src={defaultImage} img="Card Image" className={styles.cardImage} />;
        let i;
        for (i = 0; i < media.length; i++) {
            if (media[i].thumb == null) {
                att = <img src={media[i].url} img="Card Image" className={styles.cardImage} />
                break;
            }
        }

        if (i == media.length && media.length > 0) {
            if (media[0].thumb == null) {
                att = <img src={media[0].url} img="Card Image" className={styles.cardImage} />
            }
            else {
                att = <img src={media[0].thumb} img="Card Image" className={styles.cardImage} />
            }
        }


        return att;


    }

    function handleEditButton(e) {
        const { value } = e.currentTarget;
        setPostToEdit(data[value]);
    }

    function handleDeleteButton(value) {
        // console.log(e);
        // const { value } = e.currentTarget;
        dispatch(deletePostThunk({ post_id: value }));
    }

    const confirmDelete = (e) => {
        const { value } = e.currentTarget;
        confirmAlert({
            title: 'Confirm Deletion',
            message: 'Are you sure you want to delete this post.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => handleDeleteButton(value)
                },
                {
                    label: 'No',
                    onClick: () => { }
                }
            ],
            closeOnEscape: true,
            closeOnClickOutside: true,
        });
    };



    return (

        (showPostDetail !== null) ? <PostDetails id={showPostDetail} setShowPostDetail={setShowPostDetail} /> :

            <div className={styles.postfeedManagement}>
                {isCreatePostModal ? <CreatePostModal setIsPostModal={setIsCreatePostModal} setIsUpdated={setIsUpdated} /> : ""}
                {(Object.keys(postToEdit).length > 0) ? <EditPostModal data={postToEdit} setIsPostModal={setPostToEdit} setIsUpdated={setIsUpdated} /> : ""}
                <div className={styles.banner}>
                    <img src={banner} alt="Banner Image" />
                </div>
                <div className={styles.content}>
                    <div className={styles.title}>
                        <h1 className={styles.header}>Post And Feed Management</h1>
                        <button className={styles.create} onClick={() => setIsCreatePostModal(true)}>Create Post</button>

                    </div>
                    {!data.length ? (status === 'loading') ? <CircularProgress size={20} className={styles.loader} />
                        : <h3 className={styles.noPost}>No Post Found</h3> :
                        <Grid container columnSpacing={2} rowSpacing={5} className={styles.cards}>
                            {data.map(({ post_id, post_title, post_description, post_media }, index) => <Grid xl={3} lg={4} sm={6} xs={12} item key={index} >
                                <div className={styles.card}>
                                    <div className={styles.updateButtonsContainer}>
                                        <div className={styles.background} onClick={() => setShowPostDetail(post_id)}>

                                        </div>
                                        <div className={styles.updateButtons}>
                                            <button value={post_id} onClick={confirmDelete} >
                                                <RiDeleteBin6Line />
                                            </button>
                                            <button value={index} onClick={handleEditButton}>
                                                <RiEdit2Line />
                                            </button>

                                        </div>
                                    </div>
                                    {getCardThumbnail(post_media)}
                                    <div className={styles.cardInfo}>
                                        <p className={styles.tag}>{post_title}</p>
                                        {post_description.length > DESCRIPTION_LENGTH ?
                                            <p className={styles.info}>{post_description.slice(0, DESCRIPTION_LENGTH)}...  <span style={{ color: 'blue', fontWeight: 300 }}>Read More</span></p> :
                                            <p className={styles.info}>{post_description}</p>}
                                        <div className={styles.buttons}>
                                            <button className={styles.viewStats}>
                                                View Stats
                                            </button>
                                            <button className={styles.comments}>
                                                Comments
                                            </button>
                                        </div>
                                    </div>

                                </div>
                            </Grid>

                            )}
                        </Grid>
                    }

                </div>



            </div >

    )
}