import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { Post } from '../Api/Post';
import { Toast } from '../hooks/useToast';
import { Notification } from "../Api/Notification";
import { removeSpecialCharacter } from '../Prototypes';


export const getPostThunk = createAsyncThunk(
    'post/get',
    async (_, { getState }) => {
        try {
            const { api_token } = getState().profile.data;
            const response = await Post.get(api_token);
            return response.data;
        } catch (err) {
            Toast(err.message, "error", false);
            return Promise.reject(err.message);
        }
    }
)

export const createPostThunk = createAsyncThunk(
    'post/create',
    async ({ data, setIsSubmitted, setIsPostModal, setIsUpdated }, { getState }) => {
        Toast("Creating New Post ...", "loading", true);
        try {
            const { id, api_token, uuid } = getState().profile.data;
            data.append('business_id', id);
            const response = await Post.create(data, api_token);
            // const { post_title, post_description } = response.data[0];
            // try {
            //     const body = {
            //         title: post_title,
            //         body: post_description,
            //         topic: uuid
            //     }
            //     await Notification.send(body);
            // }
            // catch (e) { }
            Toast(response.message, "success", false);
            setIsPostModal(false);
            return response.data[0];
        } catch (err) {
            Toast(err.message, "error", false);
            return Promise.reject(err.message);
        }
        finally {
            setIsSubmitted(false);
        }
    }
)

export const editPostThunk = createAsyncThunk(
    'post/edit',
    async ({ data, setIsSubmitted, setIsPostModal, setIsUpdated }, { getState }) => {
        Toast("Updating Post ...", "loading", true);
        try {
            const { id, api_token } = getState().profile.data;
            data.append('business_id', id);
            const response = await Post.edit(data, api_token);
            Toast(response.message, "success", false);
            setIsPostModal(false);
            setIsUpdated(true);
        } catch (err) {
            Toast(err.message, "error", false);
            Promise.reject(err.message)
        }
        finally {
            setIsSubmitted(false);
            return;

        }
    }
)

export const deletePostThunk = createAsyncThunk(
    'post/delete',
    async ({ post_id }, { getState }) => {
        Toast("Deleting Post ...", "loading", true);
        try {
            const { id, api_token } = getState().profile.data;
            const body = {
                business_id: id,
                post_id
            }
            const response = await Post.delete(body, api_token);
            Toast(response.message, "success", false);
            return post_id;
        } catch (err) {
            Toast(err.message, "error", false);
            return Promise.reject(err.message);
        }
    }
)




const initialState = {
    status: "pending",
    data: [],
}

export const postSlice = createSlice({
    name: 'post',
    initialState,
    reducers: {
        resetState: () => {
            return initialState;
        }
    },
    extraReducers: {
        [getPostThunk.fulfilled]: (state, action) => {
            state.status = "success";
            state.data = action.payload;
        },
        [getPostThunk.pending]: (state, action) => {
            state.status = "loading"
        },
        [getPostThunk.rejected]: (state, action) => {
            state.status = "exception"
        },
        [createPostThunk.fulfilled]: (state, action) => {
            state.status = "success";
            state.data = [
                action.payload,
                ...state.data
            ];

        },
        [createPostThunk.pending]: (state, action) => {
            state.status = "loading"
        },
        [createPostThunk.rejected]: (state, action) => {
            state.status = "error"
        },
        [editPostThunk.fulfilled]: (state, action) => {
            state.status = "updated";
        },
        [editPostThunk.pending]: (state, action) => {
            state.status = "loading"
        },
        [editPostThunk.rejected]: (state, action) => {
            state.status = "error"
        },
        [deletePostThunk.fulfilled]: (state, action) => {
            const id = action.payload;
            const arr = state.data.filter(item => item.post_id != id);
            state.data = arr;
            state.status = "success";
        },
        [deletePostThunk.pending]: (state, action) => {
            state.status = "loading"
        },
        [deletePostThunk.rejected]: (state, action) => {
            state.status = "error"
        },

    }
})
export const { resetState } = postSlice.actions
export default postSlice.reducer