import React, { useState, useEffect } from "react";



/**Import Styles And Assets */
import styles from './styles/index.module.scss';

/**Import Customized Components And Hooks */
import { deleteCategoryThunk, getCategoryThunk } from "../../features/lookupsReducer";


/**Import Packaged Component */
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Stack from '@mui/material/Stack';
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from '@mui/material/CircularProgress';
import CreateCategoryModal from "./CreateCategoryModal";
import { confirmAlert } from "react-confirm-alert";

export default function MenuCategoryManagement() {

    const { data, status } = useSelector(state => state.lookups.data.category);
    const dispatch = useDispatch();




    const [page, setPage] = useState(1);
    const [isModal, setIsModal] = useState(false);

    const ROWS_PER_PAGE = 8;
    const PAGINATION_COUNT = Math.ceil(data?.length / ROWS_PER_PAGE);



    useEffect(() => {
        if (status !== 'success') {
            dispatch(getCategoryThunk());
        }
    }, [])


    const handlePageChange = (e, page) => {
        setPage(page);
    }

    const confirmDelete = (title, message, cb) => {
        confirmAlert({
            title: 'Confirm Deletion',
            message: 'Are you sure you want to delete this category.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => cb()
                },
                {
                    label: 'No',
                    onClick: () => { }
                }
            ],
            closeOnEscape: true,
            closeOnClickOutside: true,
        });
    };




    return (


        <div className={styles.reportManagement} >
            {isModal ? <CreateCategoryModal setIsModal={setIsModal} /> : ""}
            <div className={styles.content}>
                <div className={styles.title}>
                    <h1 className={styles.header}>Category Management</h1>
                    <button className={styles.create} onClick={() => setIsModal(true)}>Create Category</button>
                </div>
                <div className={styles.tableContainer}>
                    <Paper>
                        <TableContainer className={styles.tableComponent}>
                            <Table aria-label="simple table">
                                <TableHead className={styles.tableHeaderContainer}>
                                    <TableRow>
                                        <TableCell >Reporter</TableCell>
                                        <TableCell>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {status === 'loading' ?
                                        <TableRow>
                                            <TableCell colSpan={12} className={styles.loaderContainer}>
                                                <CircularProgress size={40} className={styles.loader} />
                                            </TableCell>
                                        </TableRow> :
                                        !data?.length ?
                                            <TableRow>
                                                <TableCell colSpan={12} className={styles.emptyDataContainer}>
                                                    <h1 className={styles.emptyData}>No Category Found</h1>
                                                </TableCell>
                                            </TableRow> :
                                            data.slice((page - 1) * ROWS_PER_PAGE, page * ROWS_PER_PAGE).map((row, index) => (
                                                <TableRow
                                                    key={index}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    className={styles.tableRowContainer}
                                                >
                                                    <TableCell component="th" scope="row">
                                                        {row.name}
                                                    </TableCell>
                                                    <TableCell>
                                                        <a
                                                            style={{
                                                                textDecoration: "underline",
                                                                color: "#0a58ca",
                                                                cursor: "pointer"
                                                            }}
                                                            onClick={() => {
                                                                return confirmDelete(
                                                                    '',
                                                                    '',
                                                                    () => dispatch(deleteCategoryThunk({
                                                                        category_id: row.id,
                                                                        setPage,
                                                                        isLast: (data.slice((page - 1) * ROWS_PER_PAGE, page * ROWS_PER_PAGE).length == 1)
                                                                    }))
                                                                )
                                                            }}
                                                        >
                                                            Delete
                                                        </a>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Stack spacing={2} className={styles.paginationContainer}>
                            {(data?.length > ROWS_PER_PAGE) ?
                                <Pagination
                                    count={PAGINATION_COUNT}
                                    onChange={handlePageChange}
                                    page={page}
                                    classes={{
                                        root: styles.paginaton
                                    }}
                                    renderItem={(item) => (
                                        <PaginationItem
                                            classes={{
                                                selected: styles.selectedPage
                                            }}
                                            {...item}
                                        />
                                    )}
                                /> : ""
                            }
                        </Stack>
                    </Paper>
                </div>
            </div>
        </div >
    )
}