import { Axios } from "../hooks/useAxiosInstance";

export const Reviews = (function () {


    const apis = () => ({
        get: async (id, api_token) => await getRecord(id, api_token),

    })

    async function getRecord(id, api_token) {
        const url = `api/business/getBusiReview/${id}`;
        const option = {
            headers: {
                'Content-Type': 'application/json',
                'token': api_token
            }
        }

        return await Axios.get(url, option);
    }
    return apis();

})()
