import * as yup from "yup";
import dayjs from "dayjs";
var isSameOrBefore = require('dayjs/plugin/isSameOrBefore')
dayjs.extend(isSameOrBefore);

export const registerSchema = yup.object().shape({
    name: yup.string().required("Business name is required"),
    category_id: yup.string().required("Category is required"),
    phone: yup.string().required("Phone # is required"),
    email: yup.string().email("Invalid Format").required("Email is required"),
    geo_location: yup.string().required("Business location is required"),
    fb_page: yup.string().required("Facebook page is required"),
    website: yup.string().required("Wesbite is required"),
    open_time: yup.string().required("Open time is required"),
    close_time: yup.string().required("Closing time is required"),
    password: yup.string().required("Password is required")
        .matches(/^.*(?=.{6,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/, "Password must contain at least 6 characters, one uppercase,one lowercase one number and one special case character"),
    confirm_password: yup.string().oneOf([yup.ref("password"), null], "Invalid Confirm Password").required("Confirm password is required"),
    description: yup.string()
})

export const signInSchema = yup.object().shape({
    email: yup.string().email("Invalid Format").required("Email is required"),
    password: yup.string().required("Password is required"),
})

export const resetPasswordSchema = yup.object().shape({
    email: yup.string().email("Invalid Format").required("Email is required"),
})

export const manageProfileSchema = yup.object().shape({
    name: yup.string().required("Business is required"),
    category_id: yup.string().required("Category is required"),
    phone: yup.string().required("Phone # is required"),
    email: yup.string().email("Invalid Format").required("Email is required"),
    geo_location: yup.string().required("Business Location is required"),
    fb_page: yup.string().required("Facebook Page is required"),
    website: yup.string().required("Wesbite is required"),
    open_time: yup.string().required("Open Time is required"),
    close_time: yup.string().required("Closing Time is required"),
    description: yup.string()
})

export const changePasswordSchema = yup.object().shape({
    current_password: yup.string().required("Password is required"),
    new_password: yup.string().required("New Password is required")
        .notOneOf([yup.ref("current_password"), null], "New Password should not be same as Current Password")
        .matches(/^.*(?=.{6,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/, "Password must contain at least 6 characters, one uppercase,one lowercase one number and one special case character"),
    confirm_password: yup.string().oneOf([yup.ref("new_password"), null], "Invalid Confirm Password").required("Confirm Password is required"),

})

const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "video/mp4", "image/png"];
export const createPostSchema = yup.object().shape({
    post_title: yup.string().required("Post Title Should Not Be Empty"),
    post_description: yup.string().required("Post Description Should Not Be Empty"),
})


export const createMenuSchema = yup.object().shape({
    menu_item: yup.string().required("Menu Item Should Not Be Empty"),
    menu_description: yup
        .string()
        .required("Menu Description Should Not Be Empty")
        .test("length", "Enter description up to 120 characters.", function (value) {
            return value && (value?.length <= 120);

        }),
    menu_category: yup.string().required("Menu Category Should Not Be Empty"),
    menu_old_price: yup.number().typeError("Price Should Not Be Empty").min(0, "Price Should Be Greater Than 0").required("Price Should Not Be Empty"),
    menu_new_price: yup.number()
        .typeError("Discounted Price Should Not Be Empty")
        .min(0, "Discounted Price Should Be Greater Than 0")
        .required("Discounted Price Should Not Be Empty")
        .test("Error", "Discounted price cannot be greater than Actual price", function (value) {
            const { menu_old_price } = this.parent
            return menu_old_price > value;

        })
})

export const createOfferSchema = yup.object().shape({
    title: yup.string().required("Title Should Not Be Empty"),
    geo_location: yup.string().required("Location is required"),
    age_group: yup.string().required("Age Group Should Not Be Empty"),
    gender: yup.string().required("Gender Should Not Be Empty"),
    open_time: yup.string().required("Start Date Should Not Be Empty"),
    close_time: yup.string().required("End Date  Should Not Be Empty")
        .test("Error", "Invalid Date", function (value) {
            const { open_time } = this.parent
            return dayjs(open_time).isSameOrBefore(dayjs(value));

        }),
})


export const sendNotification = yup.object().shape({
    title: yup.string().required("Title Should Not Be Empty"),
    description: yup.string().required("Description Should Not Be Empty"),
});

export const createCategorySchema = yup.object().shape({
    category_name: yup.string().required("Category Should Not Be Empty"),
});