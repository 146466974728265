import { Axios } from "../hooks/useAxiosInstance";

export const Business = (function () {

    // const { api_token } = useSelector(state => state.profile);

    const apis = () => ({
        post: async (data) => await postRecord(data),
        update: async (data, api_token) => await updateRecord(data, api_token),
        updatePassword: async (data, api_token) => await updateRecordPassword(data, api_token),
        postForgetPassword: async (data) => await postForget(data),
        create: async (data) => await createRecord(data),
        getLookups: async () => await getLookupsCategories(),
        updateRewardPoints: async (data, api_token) => await updateRewardPointsRecord(data, api_token),
    })

    async function getLookupsCategories() {
        const url = "api/business/categories";
        const option = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        return await Axios.get(url, option);
    }

    async function createRecord(data) {
        const url = "api/business/create";
        const option = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        return await Axios.post(url, data, option);
    }

    async function postRecord(data) {
        const url = "api/business/login";
        const option = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        return await Axios.post(url, data, option);
    }

    async function updateRecord(data, api_token) {
        const url = `api/business/update/${data.id}`;
        const option = {
            headers: {
                'Content-Type': 'application/json',
                'token': api_token
            }
        }

        return await Axios.put(url, data, option);
    }

    async function updateRecordPassword(data, api_token) {
        const url = `api/business/change-password`;
        const option = {
            headers: {
                'Content-Type': 'application/json',
                'token': api_token
            }
        }

        return await Axios.post(url, data, option);
    }


    async function postForget(data) {
        const url = "api/business/forget-password";
        const option = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        return await Axios.post(url, data, option);
    }

    async function updateRewardPointsRecord(data, api_token) {
        const url = `api/business/addBusiPoints`;
        const option = {
            headers: {
                'Content-Type': 'application/json',
                'token': api_token
            }
        }

        return await Axios.post(url, data, option);
    }

    return apis();

})()
