import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { Lookup } from '../Api/Lookup';
import { Toast } from '../hooks/useToast';


export const getCategoryThunk = createAsyncThunk(
    'category/menu/get',
    async (_, { getState }) => {
        try {
            const { id, api_token } = getState().profile.data;
            const response = await Lookup.getCategory(id, api_token);
            return response.menuCategory;
        } catch (err) {
            Toast(err.message, "error", false);
            return Promise.reject(err.message);
        }
    }
)

export const createMenuCategoryThunk = createAsyncThunk(
    'category/menu/create',
    async ({ category_name, setIsSubmitted, categoryRef }, { getState }) => {
        try {
            Toast("Creating New Menu Category ...", "loading", true);
            const { id, api_token } = getState().profile.data;
            const body = {
                business_id: id,
                category_name
            }
            const response = await Lookup.createMenuCategory(body, api_token);
            Toast(response.message, "success", false);
            categoryRef.current.value = "";
            return response.menuCategory;
        } catch (err) {
            Toast(err.message, "error", false);
            return Promise.reject(err.message);
        }
        finally {
            setIsSubmitted(false);
        }
    }
)


export const createCategoryThunk = createAsyncThunk(
    'category/create',
    async ({ category_name, setIsSubmitted, setIsModal }, { getState }) => {
        try {
            Toast("Creating New Menu Category ...", "loading", true);
            const { id, api_token } = getState().profile.data;
            const body = {
                business_id: id,
                category_name
            }
            const response = await Lookup.createMenuCategory(body, api_token);
            Toast(response.message, "success", false);
            setIsModal(false);
            return response.menuCategory;
        } catch (err) {
            Toast(err.message, "error", false);
            return Promise.reject(err.message);
        }
        finally {
            setIsSubmitted(false);
        }
    }
)

export const deleteCategoryThunk = createAsyncThunk(
    'category/delete',
    async ({ category_id, isLast, setPage }, { getState }) => {
        try {
            Toast("Deleting Category ...", "loading", true);
            const { id, api_token } = getState().profile.data;
            const body = {
                business_id: id,
                category_id
            }
            const response = await Lookup.deleteMenuCategory(body, api_token);
            Toast(response.message, "success", false);
            if (isLast) {
                setPage(prev => prev - 1);
            }
            return category_id
        } catch (err) {
            Toast(err.message, "error", false);
            return Promise.reject(err.message);
        }
    }
)


export const getRewardPointThunk = createAsyncThunk(
    'reward/get',
    async (_, { getState }) => {
        try {
            const { id, api_token } = getState().profile.data;
            const body = {
                user_id: id
            }

            const response = await Lookup.getRewardPoint(body, api_token);
            return response.total_points[0];
        } catch (err) {
            Toast(err.message, "error", false);
            return Promise.reject(err.message);
        }
    }
)


const initialState = {
    data: {
        category: {
            status: 'pending',
            data: []
        },
        reward: {
            status: 'pending',
            data: 0
        }
    },
}

export const lookupSlice = createSlice({
    name: 'lookup',
    initialState,
    reducers: {
        resetState: () => {
            return initialState;
        }
    },
    extraReducers: {
        [getCategoryThunk.fulfilled]: (state, action) => {
            state.data.category.status = "success";
            state.data.category.data = action.payload;

        },
        [getCategoryThunk.pending]: (state, action) => {
            state.data.category.status = "loading"
        },
        [getCategoryThunk.rejected]: (state, action) => {
            state.data.category.status = "exception"
        },
        [createMenuCategoryThunk.fulfilled]: (state, action) => {
            state.data.category.status = "success";
            state.data.category.data = action.payload;
        },
        [createMenuCategoryThunk.pending]: (state, action) => {
            state.data.category.status = "success"
        },
        [createMenuCategoryThunk.rejected]: (state, action) => {
            state.data.category.status = "exception"
        },
        [createCategoryThunk.fulfilled]: (state, action) => {
            state.data.category.status = "success";
            state.data.category.data = action.payload;
        },
        [createCategoryThunk.pending]: (state, action) => {
            state.data.category.status = "success"
        },
        [createCategoryThunk.rejected]: (state, action) => {
            state.data.category.status = "exception"
        },
        [deleteCategoryThunk.fulfilled]: (state, action) => {
            state.data.category.status = "success";
            state.data.category.data = state.data.category.data.filter(item => item.id != action.payload);
        },
        [deleteCategoryThunk.pending]: (state, action) => {
            state.data.category.status = "success"
        },
        [deleteCategoryThunk.rejected]: (state, action) => {
            state.data.category.status = "exception"
        },
        [getRewardPointThunk.fulfilled]: (state, action) => {
            const { total_points } = action.payload;
            state.data.reward.status = "success";
            state.data.reward.data = total_points;
        },
        [getRewardPointThunk.pending]: (state, action) => {
            state.data.reward.status = "loading"
        },
        [getRewardPointThunk.rejected]: (state, action) => {
            state.data.reward.status = "exception"
        },
    }
})


export const { resetState } = lookupSlice.actions

export default lookupSlice.reducer