import React from "react";
import { useDispatch } from "react-redux";
import { updateKey } from "../features/profileReducer";
import CryptoJS from 'crypto-js';


export function useLocalStorage() {

    const dispatch = useDispatch();

    const token = localStorage.getItem("web_token");

    if (token) {
        const decrypted = CryptoJS.AES.decrypt(token, process.env.REACT_APP_SECRET_KEY);

        dispatch(updateKey({ data: JSON.parse(decrypted.toString(CryptoJS.enc.Utf8)), isLoggedIn: true }));
    }

    return;


}