import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { postBusinessThunk } from "../../features/profileReducer";
import { Toast } from "../../hooks/useToast";


/**Import Styles and Assets */
import styles from "./SignIn.module.scss";
import logo from "./images/logo.png"

/**Import Packaged Component*/
import { AiFillEye, AiOutlineEyeInvisible } from "react-icons/ai";
import Grid from '@mui/material/Grid';
import { CheckboxInput, TextInput } from "../../components/Fields";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import CircularProgress from '@mui/material/CircularProgress';

/**Import Validation */
import { signInSchema } from "../../Validation";




export default function SignIn() {
    const dispatch = useDispatch();
    const { handleSubmit, control, formState: { errors }, getValues, trigger } = useForm({
        reValidateMode: "onSubmit",
        resolver: yupResolver(signInSchema)
    });

    const [isSubmitted, setIsSubmitted] = useState(false);
    const [passType, setPassType] = useState("password");
    const [checkBox, setCheckBox] = useState({
        remember_id: false,
        agree: false,
        updates: false,

    })
    const [checkBoxError, setCheckBoxError] = useState({
        agree: false,
    })



    function handleTypeChange(cb) {
        cb(prev => {
            return (prev === "text") ? "password" : "text"
        })
    }

    function handleCheckBox(name, value) {
        setCheckBox(prev => {
            return {
                ...prev,
                [name]: value
            }
        })

    }

    async function pressEnterSubmittion() {
        const value = getValues();
        await trigger()
            .then(isValid => {
                if (isValid) {
                    onSubmit(value);
                }
            })
    }

    function onSubmit(data) {
        if (checkBox.agree) {
            setIsSubmitted(true);
            dispatch(postBusinessThunk({ data, setIsSubmitted, remember_id: checkBox.remember_id }));
        }
        else {
            Toast("You must agree to Terms & Privacy Policy before loging in", "info", false);
            setCheckBoxError({ agree: true })
        }

    }

    return (
        <div className={styles.signIn}>
            <Grid container className={styles.container}>
                <Grid xs={6} className={styles.title}>
                    <div className={styles.content}>
                        <h1>Sign In Now</h1>
                        <p>More feature are available with premium purchase</p>
                    </div>
                </Grid>
                <Grid xs={6} className={styles.formContainer}>
                    <form id="signUpForm" className={styles.form} onSubmit={handleSubmit(onSubmit)}>
                        <div className={styles.header}>
                            <Link to={`/`}>
                                <img src={logo} alt="Logo" />
                            </Link>
                            <p>Not Registered Yet</p>
                            <Link to={`/register`}><button>Register</button></Link>
                        </div>

                        <h1>Sign In</h1>
                        <p>Lets get you all set up so you can verify your personal account and begin Getting up your profile</p>
                        <hr />

                        <div className={styles.fields}>

                            <TextInput
                                name="email"
                                type="text"
                                label="Email"
                                control={control}
                                error={errors}
                                autoComplete={false}
                            />
                            <TextInput
                                name="password"
                                type={passType}
                                label="Password"
                                control={control}
                                error={errors}
                                onPressEnter={() => pressEnterSubmittion()}
                                icon={passType === "password" ? <AiFillEye onClick={() => handleTypeChange(setPassType)} />
                                    : <AiOutlineEyeInvisible onClick={() => handleTypeChange(setPassType)} />}
                            />

                            <div className={styles.description}>
                                <CheckboxInput
                                    name="remember_id"
                                    value={checkBox.remember_id}
                                    onChange={(e) => handleCheckBox("remember_id", e.target.checked)}
                                    label="Remember Me"
                                />
                                <Link className={styles.reset} to={`/reset-password`}>Reset Password?</Link>
                            </div>

                            <div className={styles.information}>
                                {/* <CheckboxInput
                                    name="updates"
                                    value={checkBox.updates}
                                    onChange={(e) => handleCheckBox("updates", e.target.checked)}
                                    label="Yes I want to recieve daily updates Me"
                                /> */}
                                <CheckboxInput
                                    name="agree"
                                    error={checkBoxError}
                                    value={checkBox.agree}
                                    onChange={(e) => handleCheckBox("agree", e.target.checked)}
                                    label={<a>I agreed to all the <span>Terms Privacy, Policy and Fees</span></a>}
                                />
                            </div>

                            {isSubmitted ?
                                <button className={`${styles.submit} ${styles.submitted}`}>Sign In<CircularProgress size={20} /></button>
                                :
                                <button className={styles.submit} type="submit">Sign In </button>
                            }

                        </div>
                    </form>
                </Grid >
            </Grid >



        </div >
    )
}