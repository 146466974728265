import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";


export default function PrivateRoute({ children }) {

    const { isLoggedIn } = useSelector(state => state.profile);


    return (
        !isLoggedIn ? <Navigate to={`/`} replace /> : children

    );
}
