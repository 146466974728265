import React from "react";


import styles from "../styles/MemberShip.module.scss";
import check from "../images/check.svg"


import { Grid } from "@mui/material";

export default function MemberShip() {
    return (
        <div className={styles.membership}>
            <h1 className={styles.title}>CHOOSE YOUR BUSINESS MEMBERSHIP PLAN</h1>
            <p className={styles.subtitle}>
                Simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s.
            </p>
            <Grid container columnGap={6} rowSpacing={0} className={styles.container}>
                <Grid item className={styles.tile} lg={3} xs={3.3}>
                    <h3 className={styles.header}>Free</h3>
                    <div className={styles.pricing}>
                        <p className={styles.symbol}>$</p>
                        <h1 className={styles.amount}>0</h1>
                        <p className={styles.time}>/mo</p>
                    </div>

                    <div className={styles.points}>
                        <p className={styles.info}><img src={check} alt="Check Icon 1" />Up to 00 emails per day</p>
                        <p className={styles.info}><img src={check} alt="Check Icon 2" />Unlimited Contacts</p>
                    </div>
                    <button className={styles.button}>Read More</button>
                </Grid>
                <Grid item className={`${styles.tile} ${styles.even}`} lg={3.5} xs={3.8}>
                    <div className={styles.tag}><p>Best Value</p><div className={styles.triangle}></div></div>
                    <h3 className={styles.header}>Free</h3>
                    <div className={styles.pricing}>
                        <p className={styles.symbol}>$</p>
                        <h1 className={styles.amount}>0</h1>
                        <p className={styles.time}>/mo</p>
                    </div>

                    <div className={styles.points}>
                        <p className={styles.info}><img src={check} alt="Check Icon 3" />Up to 00 emails per day</p>
                        <p className={styles.info}><img src={check} alt="Check Icon 4" />Unlimited Contacts</p>
                    </div>
                    <button className={styles.button}>Read More</button>
                </Grid>
                <Grid item className={styles.tile} lg={3} xs={3.3}>
                    <h3 className={styles.header}>Free</h3>
                    <div className={styles.pricing}>
                        <p className={styles.symbol}>$</p>
                        <h1 className={styles.amount}>0</h1>
                        <p className={styles.time}>/mo</p>
                    </div>

                    <div className={styles.points}>
                        <p className={styles.info}><img src={check} alt="Check Icon 5" />Up to 00 emails per day</p>
                        <p className={styles.info}><img src={check} alt="Check Icon 6" />Unlimited Contacts</p>
                    </div>
                    <button className={styles.button}>Read More</button>
                </Grid>


            </Grid>

        </div>
    )
}