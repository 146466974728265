import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";


/**Import Styles And Assets */
import styles from "./styles/index.module.scss";
import defaultImage from "./images/defaultImage.png";


/**Import Components */
import { deleteMenuThunk, getMenuThunk } from "../../features/menuReducer";
import CreateMenuModal from "./CreateMenuModal";

/**Import Package Components */
import { Grid } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import { RiDeleteBin6Line, RiEdit2Line } from "react-icons/ri"
import EditMenuModal from "./EditMenuModal";


export default function MenuAndService() {

    const dispatch = useDispatch();
    const { data, status } = useSelector(state => state.menu);

    const [isCreateMenuModal, setIsCreateMenuModal] = useState(false);
    const [isUpdated, setIsUpdated] = useState(true);
    const [menuToEdit, setMenuToEdit] = useState({});

    useEffect(() => {
        if (status !== 'success' && isUpdated) {
            dispatch(getMenuThunk());
            setIsUpdated(false);

        }

    }, [isUpdated])

    function handleDeleteButton(e) {
        const { value } = e.currentTarget;
        dispatch(deleteMenuThunk({ menu_id: value }));
    }

    function handleEditButton(e) {
        const { value } = e.currentTarget;
        setMenuToEdit(data[value]);
    }

    return (
        <div className={styles.menuservicemanagement}>
            {isCreateMenuModal ? <CreateMenuModal setIsMenuModal={setIsCreateMenuModal} setIsUpdated={setIsUpdated} /> : ""}
            {(Object.keys(menuToEdit).length > 0) ? <EditMenuModal data={menuToEdit} setIsMenuModal={setMenuToEdit} setIsUpdated={setIsUpdated} /> : ""}
            <div className={styles.content}>
                <div className={styles.title}>
                    <h1 className={styles.header}>Menu And Service Management</h1>
                    <button className={styles.create} onClick={() => setIsCreateMenuModal(true)}>Create Menu</button>

                </div>
                {!data.length ? (status === 'loading') ? <CircularProgress size={20} className={styles.loader} />
                    : <h3 className={styles.noPost}>No Menu Found</h3> :
                    <Grid container columnSpacing={2} rowSpacing={5} className={styles.cards}>
                        {data.map((obj, index) => {
                            return <Card obj={obj} index={index} handleDeleteButton={handleDeleteButton} handleEditButton={handleEditButton} />
                        }
                        )}
                    </Grid>
                }
            </div>
        </div >
    )
}


export function Card({ obj, index, handleDeleteButton, handleEditButton }) {


    const {
        id,
        menu_category,
        menu_item,
        menu_old_price,
        menu_new_price,
        menu_image,
        menu_description } = obj;



    return (
        <Grid xl={2.4} lg={3} md={4} sm={6} xs={6} item key={index}>
            <div className={styles.card}>
                <div className={styles.updateButtonsContainer}>
                    <div className={styles.updateButtons}>
                        <button value={id} onClick={handleDeleteButton} >
                            <RiDeleteBin6Line />
                        </button>
                        <button value={index} onClick={handleEditButton}>
                            <RiEdit2Line />
                        </button>

                    </div>
                </div>
                <img src={(menu_image !== '') ? menu_image : defaultImage} img="Card Image" className={styles.cardImage} />
                <div className={styles.cardInfo}>
                    <p className={styles.header}>{menu_category}</p>
                    <p className={styles.info}>{menu_description}</p>
                    <div className={styles.prices}>
                        <p className={styles.newPrice}>${menu_new_price}.00</p>
                        <p className={styles.oldPrice}>${menu_old_price}.00</p>

                    </div>
                </div>

            </div>
        </Grid>
    )
}