import React, { useEffect, useState } from "react";

/**Import Components */
import { CheckboxInput, SelectDropdown, TextInput, TimeInput } from "../../components/Fields";
import Map from "../../components/Map/MapContainer";
import { Toast } from "../../hooks/useToast";

/**Import Styles and Assets */
import styles from "./Register.module.scss";
import title from "./images/title.svg"
import logo from "./images/logo.png"


/**Import Packaged Component*/
import Grid from '@mui/material/Grid';
import { FiMapPin } from "react-icons/fi";
import { AiFillEye, AiOutlineEyeInvisible } from "react-icons/ai";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { createBusinessThunk, getCategoriesThunk } from "../../features/profileReducer"
import CircularProgress from '@mui/material/CircularProgress';

/**Import Validation */
import { registerSchema } from "../../Validation";

export default function Register() {
    const dispatch = useDispatch();
    const { status, data: categories_data } = useSelector(state => state.profile.lookups.categories);

    const [isMap, setIsMap] = useState(false)
    const [passType, setPassType] = useState("password");
    const [confirmPassType, setConfirmPassType] = useState("password");
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [checkBox, setCheckBox] = useState({
        remember_id: false,
        agree: false,
        updates: false,

    })
    const [checkBoxError, setCheckBoxError] = useState({
        agree: false,
    })


    const { handleSubmit, control, formState: { errors }, watch, setValue } = useForm({
        reValidateMode: "onSubmit",
        resolver: yupResolver(registerSchema)
    });

    const geo_location = watch("geo_location");
    const [data, setData] = useState({
        open_time: dayjs().startOf('day').add(9, 'hour'),
        close_time: dayjs().startOf('day').add(17, 'hour'),
    })

    useEffect(() => {
        if (status !== "success") {
            dispatch(getCategoriesThunk());
        }
    }, [])


    useEffect(() => {
        setValue('open_time', dayjs(data.open_time).format("HH:mm:00"))
    }, [data.open_time])

    useEffect(() => {
        setValue('close_time', dayjs(data.close_time).format("HH:mm:00"))
    }, [data.close_time])



    function handleChange(name, value) {
        setData(prev => {
            return {
                ...prev,
                [name]: value
            }
        })

    }

    function handleTypeChange(cb) {
        cb(prev => {
            return (prev === "text") ? "password" : "text"
        })
    }

    function handleCheckBox(name, value) {
        setCheckBox(prev => {
            return {
                ...prev,
                [name]: value
            }
        })

    }


    function onSubmit(data) {
        if (checkBox.agree) {
            const { lat, lng } = JSON.parse(data.geo_location);
            const record = { ...data, geo_location: `${lat},${lng}` };
            setIsSubmitted(true);
            dispatch(createBusinessThunk({ data: record, setIsSubmitted }));

        }
        else {
            Toast("You must agree to Terms & Privacy Policy before submitting the form", "info", false);
            setCheckBoxError({ agree: true })
        }
    }




    return (
        <div className={styles.register}>
            {isMap ? <Map location={geo_location ? JSON.parse(geo_location) : {}} setClose={setIsMap} setLatLng={setValue} /> : ""}
            <Grid container>
                <Grid xs={4.5} className={styles.title}>
                    <div className={styles.content}>
                        <img src={title} alt="Title Image" className={styles.titleImage} />
                        <div className={styles.info}>
                            <h1>Register Your Self</h1>
                            <p>More feature are available with premium purchase</p>
                        </div>

                    </div>
                </Grid>
                <Grid xs={7.5} style={{ height: "100vh" }}>
                    <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
                        <div className={styles.header}>
                            <Link to={`/`}>
                                <img src={logo} alt="Logo" />
                            </Link>
                            <p>Already have an account?</p>
                            <Link to={`/sign-in`}><button>Sign In</button></Link>
                        </div>

                        <h1>Register</h1>
                        <p className={styles.info}>Lets get you all set up so you can verify your personal account and begin Getting up your profile</p>
                        <hr />

                        <div className={styles.fields}>
                            <Grid container rowSpacing={-2} columnSpacing={{ xs: 2 }}>
                                <Grid xs={6} item>
                                    <TextInput
                                        name="name"
                                        type="text"
                                        label="Business Name"
                                        control={control}
                                        error={errors}

                                    />
                                </Grid>

                                <Grid xs={6} item>
                                    <SelectDropdown
                                        name="category_id"
                                        type="text"
                                        label="Category"
                                        control={control}
                                        error={errors}
                                        data={categories_data}
                                        status={status}
                                        loading={status !== "success" ? true : false}

                                    />
                                </Grid>
                                <Grid xs={6} item>
                                    <TextInput
                                        name="phone"
                                        type="text"
                                        label="Phone #"
                                        control={control}
                                        error={errors}

                                    />
                                </Grid>
                                <Grid xs={6} item>
                                    <TextInput
                                        name="email"
                                        type="text"
                                        label="Email"
                                        autoComplete="no-password"
                                        control={control}
                                        error={errors}
                                    />

                                </Grid>
                                <Grid xs={6} item>
                                    <TextInput
                                        name="geo_location"
                                        type="text"
                                        label="Business Location"
                                        control={control}
                                        error={errors}
                                        value={geo_location ? `${geo_location}` : ""}
                                        icon={<FiMapPin onClick={() => setIsMap(true)} />}
                                        onFocus={() => { setIsMap(true) }}
                                        autoComplete={false}
                                        readOnly={true}
                                    />

                                </Grid>
                                <Grid xs={6} item>
                                    <TextInput
                                        name="fb_page"
                                        type="text"
                                        label="Facebook Page"
                                        control={control}
                                        error={errors}
                                    />

                                </Grid>
                                <Grid xs={6} item>
                                    <TextInput
                                        name="website"
                                        type="text"
                                        label="Website"
                                        control={control}
                                        error={errors}
                                    />

                                </Grid>
                                <Grid xs={6} item >
                                    <Grid container rowSpacing={-2} columnSpacing={{ xs: 1 }}>
                                        <Grid xs={6} item  >
                                            <TimeInput
                                                name="open_time"
                                                control={control}
                                                error={errors}
                                                type="time"
                                                label="Opening"
                                                value={data.open_time}
                                                onChange={(newValue) => handleChange("open_time", newValue)} />
                                        </Grid>
                                        <Grid xs={6} item >
                                            <TimeInput
                                                name="close_time"
                                                control={control}
                                                error={errors}
                                                type="time"
                                                label="Closing"
                                                value={data.close_time}
                                                onChange={(newValue) => handleChange("close_time", newValue)} />
                                        </Grid>
                                    </Grid>

                                </Grid>
                                <Grid xs={6} item >
                                    <TextInput
                                        name="password"
                                        type={passType}
                                        label="Password"
                                        control={control}
                                        error={errors}
                                        icon={passType === "password" ? <AiFillEye onClick={() => handleTypeChange(setPassType)} />
                                            : <AiOutlineEyeInvisible onClick={() => handleTypeChange(setPassType)} />} />


                                </Grid>
                                <Grid xs={6} item>
                                    <TextInput
                                        name="confirm_password"
                                        type={confirmPassType}
                                        label="Confirm Password"
                                        control={control}
                                        error={errors}
                                        icon={confirmPassType === "password" ? <AiFillEye onClick={() => handleTypeChange(setConfirmPassType)} />
                                            : <AiOutlineEyeInvisible onClick={() => handleTypeChange(setConfirmPassType)} />} />
                                </Grid>
                                <Grid xs={12} item>
                                    <TextInput
                                        name="description"
                                        label="Description"
                                        control={control}
                                        error={errors}
                                        multiline={true}
                                        rows={3}

                                    />
                                </Grid>
                                <Grid xs={12} item>
                                    <div className={styles.information}>
                                        {/* <CheckboxInput
                                            name="updates"
                                            error={checkBoxError}
                                            value={checkBox.updates}
                                            onChange={(e) => handleCheckBox("updates", e.target.checked)}
                                            label="Yes I want to recieve daily updates Me"
                                        /> */}
                                        <CheckboxInput
                                            name="agree"
                                            error={checkBoxError}
                                            value={checkBox.agree}
                                            onChange={(e) => handleCheckBox("agree", e.target.checked)}
                                            label={<a>I agreed to all the <span>Terms Privacy, Policy and Fees</span></a>}
                                        />
                                    </div>
                                </Grid>
                                {isSubmitted ?
                                    <button className={`${styles.submit} ${styles.submitted}`}>Register<CircularProgress size={20} /></button>
                                    :
                                    <button className={styles.submit} type="submit">Register</button>
                                }
                            </Grid>
                        </div>
                    </form>
                </Grid >
            </Grid >



        </div >
    )
}