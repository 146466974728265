import React from "react";

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-confirm-alert/src/react-confirm-alert.css';

/**Import Packages */
import {
  BrowserRouter as Router,
  Route,
  Routes

} from 'react-router-dom';

/**Import Components */
import PublicLayout from "./components/PublicLayout";
import PrivateLayout from "./components/ProtectedLayout";
import PublicRoute from "./components/Routes/PublicRoute";
import LandingPage from "./pages/LandingPage";
import SignIn from "./pages/SignIn";
import Register from "./pages/Register";
import ResetPassword from "./pages/ResetPassword";
import Dashboard from "./pages/Dashboard";
import PrivateRoute from "./components/Routes/PrivateRoute";
import ManageProfile from "./pages/ManageProfile";
import ChangePassword from "./pages/ChnagePassword";
import PostFeedManagement from "./pages/PostAndFeedManagement";
import MenuAndService from "./pages/MenuAndService";
import RatingAndReview from "./pages/RatingAndReview";
import OfferAndPromotion from "./pages/OfferAndPromotion";
import ReportManagement from "./pages/ReportManagement";
import { useLocalStorage } from "./hooks/useLocalStorage";
import Notification from "./pages/Notification";
import MenuCategoryManagement from "./pages/MenuCategoryManagement";
import RewardManagement from "./pages/RewardManagement";


function App() {

  const user = useLocalStorage();

  return (
    <Router>
      <Routes>
        <Route exact path={`/`} element={
          <PublicRoute>
            <PublicLayout isNavbar={true}>
              <LandingPage />
            </PublicLayout>
          </PublicRoute>
        } />
        <Route exact path={`/sign-in`} element={
          <PublicRoute>
            <PublicLayout isNavbar={false}>
              <SignIn />
            </PublicLayout>
          </PublicRoute>
        } />
        <Route exact path={`/register`} element={
          <PublicRoute>
            <PublicLayout isNavbar={false}>
              <Register />
            </PublicLayout>
          </PublicRoute>
        } />

        <Route exact path={`/reset-password`} element={
          <PublicRoute>
            <PublicLayout isNavbar={false}>
              <ResetPassword />
            </PublicLayout>
          </PublicRoute>
        } />
        <Route exact path={`/dashboard`} element={
          <PrivateRoute>
            <PrivateLayout selectedRoute="0" isSearchBar={true}>
              <Dashboard />
            </PrivateLayout>
          </PrivateRoute>
        } />
        <Route exact path={`/manage-profile`} element={
          <PrivateRoute>
            <PrivateLayout selectedRoute="1" isSearchBar={true}>
              <ManageProfile />
            </PrivateLayout>
          </PrivateRoute>
        } />
        <Route exact path={`/change-password`} element={
          <PrivateRoute>
            <PrivateLayout selectedRoute="2" isSearchBar={true}>
              <ChangePassword />
            </PrivateLayout>
          </PrivateRoute>
        } />
        <Route exact path={`/post-and-feed-management`} element={
          <PrivateRoute>
            <PrivateLayout selectedRoute="3" isSearchBar={true}>
              <PostFeedManagement />
            </PrivateLayout>
          </PrivateRoute>
        } />
        <Route exact path={`/menu-and-service-management`} element={
          <PrivateRoute>
            <PrivateLayout selectedRoute="4" isSearchBar={true}>
              <MenuAndService />
            </PrivateLayout>
          </PrivateRoute>
        } />
                <Route exact path={`/menu-category-management`} element={
          <PrivateRoute>
            <PrivateLayout selectedRoute="5" isSearchBar={true}>
              <MenuCategoryManagement />
            </PrivateLayout>
          </PrivateRoute>
        } />
        <Route exact path={`/rating-and-review`} element={
          <PrivateRoute>
            <PrivateLayout selectedRoute="6" isSearchBar={true}>
              <RatingAndReview />
            </PrivateLayout>
          </PrivateRoute>
        } />
        <Route exact path={`/offer-and-promotion`} element={
          <PrivateRoute>
            <PrivateLayout selectedRoute="7" isSearchBar={true}>
              <OfferAndPromotion />
            </PrivateLayout>
          </PrivateRoute>
        } />
        <Route exact path={`/report-management`} element={
          <PrivateRoute>
            <PrivateLayout selectedRoute="8" isSearchBar={true}>
              <ReportManagement />
            </PrivateLayout>
          </PrivateRoute>
        } />
        <Route exact path={`/notification`} element={
          <PrivateRoute>
            <PrivateLayout selectedRoute="9" isSearchBar={true}>
              <Notification />
            </PrivateLayout>
          </PrivateRoute>
        } />

        <Route exact path={`/reward-management`} element={
          <PrivateRoute>
            <PrivateLayout selectedRoute="10" isSearchBar={true}>
              <RewardManagement />
            </PrivateLayout>
          </PrivateRoute>
        } />
      </Routes>
    </Router>
  );
}

export default App;
