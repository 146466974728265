import { Axios } from "../hooks/useAxiosInstance";

export const Post = (function () {

    // const { api_token } = useSelector(state => state.profile);

    const apis = () => ({
        get: async (api_token) => await getRecord(api_token),
        getByID: async (id, api_token) => await getRecordByID(id, api_token),
        create: async (data, api_token) => await createRecord(data, api_token),
        edit: async (data, api_token) => await editRecord(data, api_token),
        delete: async (data, api_token) => await deleteRecord(data, api_token),

    })

    async function getRecord(api_token) {
        const url = "api/business/getBusiPosts";
        const option = {
            headers: {
                'Content-Type': 'application/json',
                'token': api_token
            }
        }

        return await Axios.get(url, option);
    }
    async function getRecordByID(id, api_token) {
        const url = `api/business/getPostById/${id}`;
        const option = {
            headers: {
                'Content-Type': 'application/json',
                'token': api_token
            }
        }

        return await Axios.get(url, option);
    }

    async function createRecord(data, api_token) {
        const url = "api/business/createPost";
        const option = {
            headers: {
                'Content-Type': 'multipart/form-data',
                'token': api_token
            }
        }

        return await Axios.post(url, data, option);
    }


    async function editRecord(data, api_token) {
        const url = "api/business/editPosts";
        const option = {
            headers: {
                'Content-Type': 'multipart/form-data',
                'token': api_token
            }
        }

        return await Axios.post(url, data, option);
    }

    async function deleteRecord(data, api_token) {
        const url = "api/business/deletePost";
        const option = {
            headers: {
                'Content-Type': 'application/json',
                'token': api_token
            }
        }

        return await Axios.post(url, data, option);
    }

    return apis();

})()
