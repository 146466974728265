import { Axios } from "../hooks/useAxiosInstance";

export const Menu = (function () {

    // const { api_token } = useSelector(state => state.profile);

    const apis = () => ({
        get: async (id, api_token) => await getRecord(id, api_token),
        create: async (data, api_token) => await createRecord(data, api_token),
        edit: async (data, api_token) => await editRecord(data, api_token),
        delete: async (data, api_token) => await deleteRecord(data, api_token),

    })

    async function getRecord(id, api_token) {
        const url = `api/business/getMenu/${id}`;
        const option = {
            headers: {
                'Content-Type': 'application/json',
                'token': api_token
            }
        }

        return await Axios.get(url, option);
    }

    async function createRecord(data, api_token) {
        const url = "api/business/createMenu";
        const option = {
            headers: {
                'Content-Type': 'multipart/form-data',
                'token': api_token
            }
        }

        return await Axios.post(url, data, option);
    }


    async function editRecord(data, api_token) {
        const url = "api/business/editMenu";
        const option = {
            headers: {
                'Content-Type': 'multipart/form-data',
                'token': api_token
            }
        }

        return await Axios.post(url, data, option);
    }

    async function deleteRecord(data, api_token) {
        const url = "api/business/deleteMenu";
        const option = {
            headers: {
                'Content-Type': 'application/json',
                'token': api_token
            }
        }

        return await Axios.post(url, data, option);
    }

    return apis();

})()
