import React from "react";
import { Link } from "react-router-dom"
import styles from "./ProtectedLayout.module.scss";
import Navbar from "./Navbar";


/**Import Assets */
import logo from "./images/logo.png";
import { useDispatch } from "react-redux";
import dashboard from "./images/dashboard.svg"
import profile from "./images/profile.svg"
import edit from "./images/edit.svg"
import postfeed from "./images/postfeed.svg";
import menuservice from "./images/menuservice.svg"
import ratingreview from "./images/ratingreview.svg"
import offerpromotion from "./images/offerpromotion.svg"
import category from "./images/category.svg"
import reward from "./images/reward.svg"
import report from "./images/report.svg"
import activedashboard from "./images/activeDashboard.svg"
import activeprofile from "./images/activeProfile.svg";
import activeedit from "./images/activeedit.svg";
import activepostfeed from "./images/activepostfeed.svg";
import activemenuservice from "./images/activeMenuService.svg"
import activeratingreview from "./images/activeratingreview.svg"
import activeofferpromotion from "./images/activeofferpromotion.svg"
import activereport from "./images/activereport.svg"
import notification from "./images/notification.svg"
import activenotification from "./images/activenotification.svg"
import activereward from "./images/activereward.svg"
import activecategory from "./images/activecategory.svg"
import logout from "./images/10.svg"



/**Import Components from Packages */
import Grid from '@mui/material/Grid';
import { resetState as dashboardReset } from "../../features/dashboardReducer";
import { resetState as lookUpsReset } from "../../features/lookupsReducer";
import { resetState as menuReset } from "../../features/menuReducer";
import { resetState as offerReset } from "../../features/offersReducer";
import { resetState as postReset } from "../../features/postReducer";
import { resetState as profileReset } from "../../features/profileReducer";
import { resetState as reviewsReset } from "../../features/reviewsReducer";
import { resetState as reportReset } from "../../features/reportReducer";
import { confirmAlert } from "react-confirm-alert";

export default function ProtectedLayout({ selectedRoute, isSearchBar, children }) {

    const dispatch = useDispatch();


    const items = [
        {
            label: "Dashboard",
            icon: dashboard,
            activeIcon: activedashboard
        },
        {
            label: "Manage Profile",
            icon: profile,
            activeIcon: activeprofile
        },
        {
            label: "Change Password",
            icon: edit,
            activeIcon: activeedit
        },
        {
            label: "Post And Feed Management",
            icon: postfeed,
            activeIcon: activepostfeed
        },
        {
            label: "Menu And Service Management",
            icon: menuservice,
            activeIcon: activemenuservice
        },
        {
            label: "Menu Category Management",
            icon: category,
            activeIcon: activecategory
        },
        {
            label: "Rating And Review",
            icon: ratingreview,
            activeIcon: activeratingreview
        },
        {
            label: "Offer And Promotion",
            icon: offerpromotion,
            activeIcon: activeofferpromotion
        },
        {
            label: "Report Management",
            icon: report,
            activeIcon: activereport
        },
        {
            label: "Notification",
            icon: notification,
            activeIcon: activenotification
        },
        {
            label: "Reward Management",
            icon: reward,
            activeIcon: activereward
        }

    ]

    const handleLogout = () => {
        localStorage.removeItem('web_token')
        dispatch(dashboardReset());
        dispatch(lookUpsReset())
        dispatch(menuReset())
        dispatch(offerReset())
        dispatch(postReset())
        dispatch(profileReset())
        dispatch(reviewsReset())
        dispatch(reportReset())
    }

    const confirmLogout = (title, message, cb) => {
        confirmAlert({
            title: 'Confirm Logout',
            message: 'Are you sure you want to logout?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => cb()
                },
                {
                    label: 'No',
                    onClick: () => { }
                }
            ],
            closeOnEscape: true,
            closeOnClickOutside: true,
        });
    };
    return (
        <div className={styles.protectedLayout}>
            <div >
                <Navbar isSearchBar={isSearchBar} />
            </div>
            <Grid container className={styles.layout}>
                <Grid item xl={2.5} lg={3.1} xs={3.7} className={styles.sider}>
                    <div className={styles.children}>
                        <div className={styles.menu}>
                            {items.map(({ label, icon, activeIcon }, index) => {
                                return <Link to={`/${label.toLowerCase().replace(/[^A-Z0-9]/ig, "-")}`} className={`${styles.item} ${index == selectedRoute ? styles.active : ""}`} key={index}>
                                    <img src={(index == selectedRoute) ? activeIcon : icon} alt={`${label} Icon`} />
                                    <p>{label}</p>
                                </Link>
                            })}
                            <Link className={styles.item} onClick={() => confirmLogout('', '', () => handleLogout())}>
                                <img src={logout} alt={`Logout Icon`} />
                                <p>Logout</p>
                            </Link>

                        </div>

                    </div>
                </Grid>
                <Grid item xl={9.5} lg={8.9} xs={8.3} className={styles.routeContent}>
                    <div className={styles.content}>
                        {children}
                    </div>
                </Grid>

            </Grid>
        </div>
    )
}